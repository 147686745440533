import { DEFAULT_GUEST_FEEDBACK_RESPONSE } from './constants'
import { GuestFeedbackResponse, GuestFeedbackState } from './types'

export const formatResponse = (
  response: GuestFeedbackResponse | undefined
): GuestFeedbackResponse => {
  return response || DEFAULT_GUEST_FEEDBACK_RESPONSE
}

export const createGuestFeedbackState = (
  response: GuestFeedbackResponse
): GuestFeedbackState => {
  return {
    ...response.feedback,
    contactInformation: {
      ...response?.feedback?.contactInformation,
      optIn: true
    },
    feedbackFreeFormText: '',
    complete: true
  }
}
