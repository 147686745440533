import { useSearchParams } from 'react-router-dom'
import { FeedbackRating } from '../domain/guestFeedbackDetailsContext'

const URL_PARAM_RATING = 'rating'

export const useGuestFeedbackRating = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const setRating = (newRating: FeedbackRating) => () => {
    searchParams.delete(URL_PARAM_RATING)
    searchParams.set(URL_PARAM_RATING, newRating)
    setSearchParams(searchParams)
  }

  return {
    rating: getRating(searchParams),
    setPositive: setRating('POSITIVE'),
    setNegative: setRating('NEGATIVE')
  }
}

// Note these are the valid ratings as 'PASSIVE' is not currently valid in the UI
const validRatings: FeedbackRating[] = ['POSITIVE', 'NEGATIVE']

const getRating = (
  searchParams: URLSearchParams
): FeedbackRating | undefined => {
  const rating = searchParams.get(URL_PARAM_RATING) || ''
  const ratingUppercase = rating.toUpperCase()
  return validRatings.find((r) => r === ratingUppercase)
}
