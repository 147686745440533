import axios, { AxiosPromise } from 'axios'
import {
  ToastEnvironment,
  getCurrentEnvironment
} from '@toasttab/current-environment'
import { GuestFeedbackUrlData } from '../guestFeedbackUrlData'
import {
  G2_FRONTEND_URL_DEV,
  G2_FRONTEND_URL_PREPROD,
  G2_FRONTEND_URL_PROD,
  G2_FRONTEND_URL_SANDBOX,
  GUEST_FEEDBACK_GET_URL,
  GUEST_FEEDBACK_PUT_URL
} from './constants'
import {
  GuestFeedbackPayload,
  GuestFeedbackResponse
} from '../guestFeedbackDetailsContext'

const getGuestFeedback = ({
  shortUrl,
  restaurantGuid,
  checkGuid,
  orderGuid,
  orderPaymentGuid,
  search
}: GuestFeedbackUrlData): AxiosPromise<GuestFeedbackResponse> => {
  const url = GUEST_FEEDBACK_GET_URL.replace('{SHORT_URL}', shortUrl)
    .replace('{RESTAURANT_GUID}', restaurantGuid)
    .replace('{CHECK_GUID}', checkGuid)
    .replace('{ORDER_GUID}', orderGuid)
    .replace('{ORDER_PAYMENT_GUID}', orderPaymentGuid)
    .replace('{SEARCH}', search)
  return axios.get(url)
}

export const getG2FrontendUrl = () => {
  const env = getCurrentEnvironment()

  if (env === ToastEnvironment.DEV) {
    return G2_FRONTEND_URL_DEV
  } else if (env === ToastEnvironment.SANDBOX) {
    return G2_FRONTEND_URL_SANDBOX
  } else if (env === ToastEnvironment.PREPROD) {
    return G2_FRONTEND_URL_PREPROD
  } else {
    return G2_FRONTEND_URL_PROD // prod as default
  }
}

const putGuestFeedback = (
  feedbackGuid: string,
  data: GuestFeedbackPayload
): AxiosPromise<void> => {
  const baseUrl = getG2FrontendUrl()
  const url =
    baseUrl + GUEST_FEEDBACK_PUT_URL.replace('{FEEDBACK_GUID}', feedbackGuid)
  return axios.put(url, data)
}

export const api = {
  getGuestFeedback,
  putGuestFeedback
}
