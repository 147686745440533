import * as React from 'react'
import { HEADER_STYLES } from '../../domain/backgrounds'
import { useGuestFeedbackRating } from '../../hooks/useGuestFeedbackRating'
import { Footer } from '../GuestFeedbackPage/Footer'
import { onEnter } from '../../domain/events'
import { trackEvent } from '../../domain/tracking'
import ThumbsUp from '../../../assets/thumbs-up.svg'
import ThumbsDown from '../../../assets/thumbs-down.svg'

export const GuestFeedbackRatingPage = () => {
  const { setPositive, setNegative } = useGuestFeedbackRating()

  React.useEffect(() => {
    trackEvent({ name: 'URL without rating' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='flex flex-col h-screen'>
      <div className='flex-grow w-full' style={HEADER_STYLES}>
        <div className='px-4 pb-12 mx-auto' style={{ maxWidth: '20rem' }}>
          <h1 className='type-headline-4 text-default text-center pt-24'>
            How was <br />
            your experience?
          </h1>
          <div className='flex justify-center items-center gap-3 pt-10'>
            <div
              role='button'
              tabIndex={0}
              onClick={setPositive}
              onKeyPress={onEnter(setPositive)}
              className='outline-none focus:shadow-focus rounded text-gray-50 hover:text-primary-75'
              aria-label='Positive feedback'
            >
              <ThumbsUp />
            </div>
            <div
              role='button'
              tabIndex={0}
              onClick={setNegative}
              onKeyPress={onEnter(setNegative)}
              className='outline-none focus:shadow-focus rounded text-gray-50 hover:text-primary-75'
              aria-label='Negative feedback'
            >
              <ThumbsDown />
            </div>
          </div>
          <p className='type-default text-default text-center pt-10'>
            We value your feedback as it helps us continually improve.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}
