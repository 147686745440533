import { useMutation } from 'react-query'
import { api } from '../domain/api'
import { GuestFeedbackPayload } from '../domain/guestFeedbackDetailsContext'

interface UseMutateGuestFeedbackProps {
  feedbackGuid: string
  onSuccess?: () => void
  onError?: () => void
}

export const useMutateGuestFeedback = ({
  feedbackGuid,
  onSuccess,
  onError
}: UseMutateGuestFeedbackProps) => {
  const { isLoading, mutate, isSuccess } = useMutation(
    (data: GuestFeedbackPayload) => {
      return api.putGuestFeedback(feedbackGuid, data)
    },
    {
      onSuccess,
      onError
    }
  )

  return {
    mutate,
    isLoading,
    isSuccess
  }
}
