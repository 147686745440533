import * as React from 'react'
import { Rating } from './Rating'
import { Reasons } from './Reasons'
import { TextFeedback } from './TextFeedback'
import { LearnMore } from './LearnMore'
import { SubmitButton } from './SubmitButton'
import { InformationProcessingText } from './InformationProcessingText'

export const GuestFeedbackForm = () => {
  return (
    <>
      <Rating />
      <Reasons />
      <TextFeedback />
      <LearnMore />
      <SubmitButton />
      <InformationProcessingText />
    </>
  )
}
