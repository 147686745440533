import * as React from 'react'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { Button } from '@toasttab/buffet-pui-buttons'

export const SubmitButton = () => {
  const { saveFeedback, saving } = useGuestFeedbackContext()

  return (
    <Button className='w-full mt-4' onClick={saveFeedback} disabled={saving}>
      {saving ? 'Sending...' : 'Share feedback'}
    </Button>
  )
}
