import * as React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { GuestFeedback } from '../GuestFeedback/GuestFeedback'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
})

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackBarProvider>
        <BrowserRouter basename='/'>
          <Routes>
            <Route path='*' element={<GuestFeedback />} />
          </Routes>
        </BrowserRouter>
      </SnackBarProvider>
    </QueryClientProvider>
  )
}
