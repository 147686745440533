import * as React from 'react'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { onEnter } from '../../domain/events'
import ThumbsUp from '../../../assets/thumbs-up.svg'
import ThumbsDown from '../../../assets/thumbs-down.svg'

export const Rating = () => {
  const { state, updateState } = useGuestFeedbackContext()
  const { feedbackRating } = state
  const isPositive = feedbackRating === 'POSITIVE'

  const onToggleRating = () => {
    const newFeedbackRating = isPositive ? 'NEGATIVE' : 'POSITIVE'
    updateState({ feedbackRating: newFeedbackRating })
  }

  return (
    <div className='pt-12 px-4 flex flex-col items-center'>
      <div className='text-primary-75'>
        {isPositive ? (
          <ThumbsUp aria-label='Positive feedback' />
        ) : (
          <ThumbsDown aria-label='Negative feedback' />
        )}
      </div>
      <p className='type-default text-default text-center'>
        I had a {isPositive ? 'positive' : 'negative'} experience
      </p>
      <div
        role='button'
        tabIndex={0}
        onClick={onToggleRating}
        onKeyPress={onEnter<HTMLInputElement>(onToggleRating)}
        className='type-subhead text-gray-75 hover:text-gray-100 underline px-1 outline-none focus:shadow-focus rounded select-none'
      >
        I changed my mind
      </div>
    </div>
  )
}
