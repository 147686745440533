import * as React from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { Header } from './Header'
import { PageContent } from './PageContent'
import { Footer } from './Footer'
import { HEADER_STYLES } from '../../domain/backgrounds'

export const GuestFeedbackPage = () => {
  const { isLoading } = useGuestFeedbackContext()

  if (isLoading) {
    return <MerryGoRound className='pin-center' size='md' />
  }

  return (
    <div className='flex flex-col h-screen'>
      <Header />
      <div className='flex-grow w-full' style={HEADER_STYLES}>
        <div className='px-4 pb-12 mx-auto' style={{ maxWidth: '23rem' }}>
          <PageContent />
        </div>
      </div>
      <Footer />
    </div>
  )
}
