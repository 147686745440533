import * as React from 'react'
import { getThisYear } from '../../domain/date'

const LINK_STYLE = 'text-link hover:text-link-hover hover:underline'

export const Footer = () => {
  return (
    <>
      <p className='pt-t px-4 text-center type-large text-default max-w-xl mx-auto'>
        If you have previously opted-in to marketing communications from this
        restaurant, that opt-in will not be affected by your selection. See
        <a
          href='https://pos.toasttab.com/ie/terms-of-service'
          className={LINK_STYLE}
        >
          {' '}
          Terms{' '}
        </a>{' '}
        for information on how to unsubscribe.
      </p>
      <p className='p-4 text-center type-subhead text-default'>
        Powered by{' '}
        <a
          href='https://pos.toasttab.com/?source=footer'
          className={LINK_STYLE}
        >
          Toast
        </a>
        <br />© Toast, Inc. {getThisYear()}. All Rights Reserved. |{' '}
        <a
          href='https://pos.toasttab.com/terms-of-service'
          className={LINK_STYLE}
        >
          Terms of Service
        </a>{' '}
        |{' '}
        <a
          href='https://blog.toasttab.com/?source=footer'
          className={LINK_STYLE}
        >
          Toast Blog
        </a>
        <br />
        Information you provide may be used by the Merchant to contact you about
        your feedback, and improve your experience. Your data will be processed
        pursuant to Toast's{' '}
        <a href='https://pos.toasttab.com/privacy' className={LINK_STYLE}>
          Privacy Statement
        </a>
        . Additional information for California residents available{' '}
        <a
          href='https://pos.toasttab.com/privacy#addendum-a'
          className={LINK_STYLE}
        >
          here
        </a>
        .
      </p>
    </>
  )
}
