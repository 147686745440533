import * as React from 'react'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { TextArea } from '../Inputs/TextArea'

export const TextFeedback = () => {
  const { state, updateState } = useGuestFeedbackContext()
  const { feedbackFreeFormText } = state

  const onChangeTextArea = (newValue: string) => {
    updateState({ feedbackFreeFormText: newValue })
  }

  return (
    <div className='pt-6'>
      <TextArea
        testId='text-feedback'
        className='h-28'
        placeholder='Tell us a bit more (max 250 characters)'
        maxChars={250}
        value={feedbackFreeFormText}
        onChange={onChangeTextArea}
      />
    </div>
  )
}
