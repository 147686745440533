import { useQuery } from 'react-query'
import { api } from '../domain/api'
import {
  formatResponse,
  GuestFeedbackQuery,
  GuestFeedbackResponse
} from '../domain/guestFeedbackDetailsContext'
import { useGuestFeedbackUrlData } from './useGuestFeedbackUrlData'
import { AxiosResponse } from 'axios'

interface UseQueryGuestFeedbackProps {
  enabled: boolean
  onLoadSuccess: (data: GuestFeedbackResponse) => void
  onLoadError: () => void
}

export const useQueryGuestFeedback = ({
  enabled,
  onLoadSuccess,
  onLoadError
}: UseQueryGuestFeedbackProps): GuestFeedbackQuery => {
  const guestFeedbackUrlData = useGuestFeedbackUrlData()
  const { isBadUrlData } = guestFeedbackUrlData

  const { isLoading, isError, data, refetch } = useQuery(
    ['queryGuestFeedbackDetails', guestFeedbackUrlData],
    () => api.getGuestFeedback(guestFeedbackUrlData),
    {
      enabled: enabled && !isBadUrlData,
      cacheTime: 0,
      staleTime: Infinity,
      onSuccess: (requestData: AxiosResponse<GuestFeedbackResponse>) => {
        onLoadSuccess(formatResponse(requestData?.data))
      },
      onError: onLoadError
    }
  )

  return {
    isLoading,
    isError,
    refetch,
    ...formatResponse(data?.data)
  }
}
