import * as React from 'react'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { isIntl } from '../../domain/international'

const LINK_STYLE = 'text-link hover:text-link-hover hover:underline'
const TERMS_OF_SERVICE = (
  <a
    href='https://pos.toasttab.com/terms-of-service'
    target='_blank'
    className={LINK_STYLE}
    rel='noreferrer'
  >
    Terms of Service
  </a>
)
const PRIVACY_STATEMENT = (
  <a
    href='https://pos.toasttab.com/privacy'
    target='_blank'
    className={LINK_STYLE}
    rel='noreferrer'
  >
    Privacy Statement
  </a>
)

export const InformationProcessingText = () => {
  const { intlProps } = useGuestFeedbackContext()

  if (isIntl(intlProps)) {
    return (
      <>
        <p
          data-testid='info-processing-text-output'
          className='p-4 text-center type-subhead text-secondary'
        >
          The information you provide will be processed pursuant to Toast's{' '}
          {TERMS_OF_SERVICE} and {PRIVACY_STATEMENT}.
        </p>
      </>
    )
  }
  return null
}
