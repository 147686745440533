import * as React from 'react'
import { TIP_STYLES } from '../../domain/backgrounds'
import Success from '../../../assets/outcome-success.svg'
import Bowl from '../../../assets/outcome-bowl.svg'
import Paper from '../../../assets/outcome-paper.svg'

interface GuestFeedbackOutcomeProps {
  icon: React.ReactNode
  title: string
  description: string
  tipTitle: string
  tipDescription: string
}

export const GuestFeedbackOutcome = ({
  icon,
  title,
  description,
  tipTitle,
  tipDescription
}: GuestFeedbackOutcomeProps) => {
  return (
    <div className='flex flex-col items-center'>
      <div className='pt-16'>{icon}</div>
      <p className='pt-6 type-headline-4 font-medium text-default text-center'>
        {title}
      </p>
      <p className='type-default text-default pb-8 px-4 text-center'>
        {description}
      </p>
      <div className='w-full bg-success-0 rounded pl-4 py-4' style={TIP_STYLES}>
        <p className='type-default text-default font-bold'>{tipTitle}</p>
        <p className='type-default text-default'>{tipDescription}</p>
      </div>
    </div>
  )
}

export const GuestFeedbackOutcomePositive = () => {
  return (
    <GuestFeedbackOutcome
      icon={<Success />}
      title='Thank you!'
      description="We're glad you enjoyed the experience and we hope to see you again soon!"
      tipTitle='Care to share?'
      tipDescription='Support us by sharing your positive experience online!'
    />
  )
}

export const GuestFeedbackOutcomeNegative = () => {
  return (
    <GuestFeedbackOutcome
      icon={<Bowl />}
      title='Thank you!'
      description='We value all feedback as it helps us continually improve.'
      tipTitle='More thoughts?'
      tipDescription='Feel free to share more feedback by contacting us directly.'
    />
  )
}

export const GuestFeedbackOutcomeExpired = () => {
  return (
    <GuestFeedbackOutcome
      icon={<Paper />}
      title='Thank you!'
      description='You already left feedback for this experience or too much time has passed.'
      tipTitle='We want to hear from you!'
      tipDescription='Feel free to share feedback by contacting us directly.'
    />
  )
}

export const GuestFeedbackOutcomeError = () => {
  return (
    <GuestFeedbackOutcome
      icon={<Bowl />}
      title='Oh no!'
      description='Something went wrong, please try again later.'
      tipTitle='We want to hear from you!'
      tipDescription='Feel free to share feedback by contacting us directly.'
    />
  )
}
