import * as React from 'react'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'

export const LearnMore = () => {
  const { state, updateState } = useGuestFeedbackContext()
  const optIn = state.contactInformation?.optIn

  const onToggleOptIn = () => {
    updateState({
      contactInformation: {
        optIn: !optIn
      }
    })
  }

  return (
    <div className='pt-2'>
      <Checkbox
        label={
          optIn
            ? 'Yes, you can contact me to learn more'
            : "No, you can't contact me to learn more"
        }
        checked={optIn}
        onChange={onToggleOptIn}
      />
    </div>
  )
}
